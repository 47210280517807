import * as Phaser from "phaser";
import * as spine from "@esotericsoftware/spine-phaser";

const cardImages = {
  Common: "/assets/noi/c02.png",
  Uncommon: "/assets/noi/bf02.png",
  Rare: "/assets/noi/sp02.png",
  Epic: "/assets/noi/wk02.png",
  legendery: "/assets/noi/wk02.png",
};
const prizeToImage = {
  Cash: "/assets/cards-icons/cash.png",
  "Bonus Funds": "/assets/cards-icons/bonus_funds.png",
  "Free Spins": "/assets/cards-icons/free_spins.png",
  "Free Bet": "/assets/cards-icons/free_bets.png",
  FunCoins: "/assets/cards-icons/win_coins.png",
  "Golden Chips": "/assets/cards-icons/golden_chips.png",
  "Wheel Spins": "/assets/cards-icons/wheel_spins.png",
  "Loyalty Points": "/assets/cards-icons/loyalty_points.png",
  "Shop Points": "/assets/cards-icons/shop_points.png",
  Multiplier: "/assets/cards-icons/multiplier.png",
};
const loot = document.querySelector(".loot");

export class SpineDemo extends Phaser.Scene {
  constructor() {
    super({ key: "SpineDemo" });
    this.isAnimationPlaying = false;
    this.cardIndex = 0;
    this.openedCards = []; // Array pentru a stoca cardurile deschise
    this.gameOver = false; // Variabilă nouă pentru a indica sfârșitul jocului
    this.selectedPullType = null; // Variabilă pentru stocarea tipului de pull selectat
  }

  //   preload() {
  //     this.load.image("particle", "assets/white-flare.png");

  //     fetch("http://127.0.0.1:3000/api/getChest")
  //       .then((response) => response.json())
  //       .then((data) => {
  //         const chestNumber = data.chest_number;
  //         const chestPath = `assets/chest_${chestNumber}/`;

  //         this.load.spineBinary("lootbox-data", `${chestPath}skeleton.skel`);
  //         this.load.spineAtlas("lootbox-atlas", `${chestPath}export.atlas`);
  //         this.load.audio("open2Sound", `/assets/chest2.mp3`);
  //       })
  //       .catch((error) => {
  //         console.error("Error fetching chest data:", error);

  //         const defaultChestPath = "assets/chest_1/";
  //         this.load.spineBinary(
  //           "lootbox-data",
  //           `${defaultChestPath}skeleton.skel`
  //         );
  //         this.load.spineAtlas(
  //           "lootbox-atlas",
  //           `${defaultChestPath}export.atlas`
  //         );
  //         this.load.audio("open2Sound", `/assets/chest2.mp3`);
  //       });
  //   }
  preload() {
    this.load.image("particle", "assets/white-flare.png");

    // Randomly generate a chest number between 1 and 5
    // const chestNumber = Math.floor(Math.random() * 2) + 1;
    const chestNumber = 1;
    console.log("chestNumber", chestNumber);
    const chestPath = `assets/chest_${chestNumber}/`;

    this.load.spineBinary("lootbox-data", `${chestPath}skeleton.skel`);
    this.load.spineAtlas("lootbox-atlas", `${chestPath}export.atlas`);
    this.load.audio("open2Sound", `/assets/chest2.mp3`);
    this.load.audio("cash", `/assets/cash.mp3`);
  }

  create() {
    const self = this; // Păstrează o referință la instanța scenei
    // game.time.advancedTiming = true;
    // this.cameras.main.setZoom(0.4);

    const centerX = this.cameras.main.width / 2;
    const centerY = this.cameras.main.height / 2;
    const lootbox = this.add.spine(
      centerX,
      centerY,
      "lootbox-data",
      "lootbox-atlas"
    );
    // lootbox.customMaterial.texture.setFilter(Phaser.Textures.FilterMode.LINEAR);
    // this.scale.resize(window.innerWidth, window.innerHeight);
    document.getElementById("regularButton").addEventListener("click", () => {
      const buttonContainer = document.getElementById("buttonContainer");
      console.log("regular");
      this.selectedPullType = "regular";
      buttonContainer.classList.add("hide");
    });

    document.getElementById("uncommonButton").addEventListener("click", () => {
      console.log("uncommon");
      this.selectedPullType = "uncommon";
      const buttonContainer = document.getElementById("buttonContainer");
      buttonContainer.classList.add("hide");
    });

    document
      .getElementById("multiplierButton")
      .addEventListener("click", () => {
        console.log("multiplier");
        this.selectedPullType = "multiplier";
        const buttonContainer = document.getElementById("buttonContainer");
        buttonContainer.classList.add("hide");
      });

    document.getElementById("legendaryButton").addEventListener("click", () => {
      console.log("legendary");
      this.selectedPullType = "legendary";
      const buttonContainer = document.getElementById("buttonContainer");
      buttonContainer.classList.add("hide");
    });
    // lootbox.scale = 0.4;
    if (window.innerWidth < 800) {
      lootbox.scale = 1;
      this.cameras.main.setZoom(2);
    } else {
      lootbox.scale = 1;
      // this.cameras.main.setZoom(0.1);
    }

    if (this.sys.game.device.os.iPad) {
      console.log("Rulează pe ipad");
      this.cameras.main.setZoom(1.5);
    }
    if (this.sys.game.device.os.iPhone) {
      console.log("Rulează pe iphone");
    }

    this.input.on("pointerdown", () => {
      if (self.isAnimationPlaying || self.gameOver) {
        // Verifică dacă jocul s-a încheiat
        return;
      }

      const backFace = document.querySelector(".overlay");
      //   backFace.classList.add('blink'); // Adaugă efectul de shine

      //   backFace.addEventListener('animationend', function() {
      //     backFace.classList.remove('blink'); // Elimină clasa după finalizarea animației
      //   }, { once: true }); // Listener-ul
      //   self.isAnimationPlaying = true;

      function animateValue(element, start, end, duration) {
        const range = end - start;
        const stepTime = 10; // milliseconds per step
        const steps = duration / stepTime; // total number of steps
        const increment = range / steps; // value to increment each step to ensure we finish on time

        let current = start;
        const timer = setInterval(() => {
          current += increment;
          // Ensure we do not exceed the end value or drop below the start value
          if (
            (increment > 0 && current > end) ||
            (increment < 0 && current < start)
          ) {
            element.textContent = end; // Ensure the final number is exactly what it should be
            clearInterval(timer);
          } else {
            element.textContent = Math.floor(current); // Update with the current count, rounded down
          }
        }, stepTime);
      }
      fetch(`http://127.0.0.1:3000/api/${this.selectedPullType}`)
        .then((response) => response.json())
        .then((data) => {
          const {
            card_type,
            prize,
            won_amount,
            currencySymbol,
            additional_value,
          } = data;
          const cardwins = document.querySelector(".cardwins");
          cardwins.classList.remove("hide");
          const additionalValueText =
            document.querySelector(".additional-value");
          // additionalValueText.textContent = additional_value;
          const cardBottom = document.querySelector(".cardbottom");
          // cardBottom.classList.add("animate__headShake");
          additionalValueText.textContent = "0"; // începe de la 0
          animateValue(additionalValueText, 0, additional_value, 1000); // Durata poate fi ajustată după nevoie
          const cardTypeElement = document.querySelector(".cardType");
          cardTypeElement.textContent = card_type.toUpperCase(); // Display the card type
          cardTypeElement.className = `cardType ${card_type.toLowerCase()}`; // Update class to reflect the type
          void cardTypeElement.offsetWidth; // Force reflow

          cardTypeElement.classList.add("slide-in-right");

          // Mapping of prize types to display texts
          const detailsMap = {
            Cash: "Your balance",
            "Bonus Funds": "Your balance",
            "Free Spins": "Your free spins",
            "Free Bets": "Total Free Bet",
            "Golden Chips": "Total Golden Chips",
            "Loyalty Points": "Your Loyalty Points",
            Multiplier: "All wins in this chest are multiplied x5",
            "Shop Points": "Your Shop Points",
            "Wheel Spins": "Spin the wheel multiple times!",
            FunCoins: "Total WinCoins",
          };
          // Image map for specific card types
          const cardImageMap = {
            "Free Bets": "/assets/prize-icons/free-bets.png",
            "Free Spins": "/assets/prize-icons/free-spins.png",
            "Golden Chips": "/assets/prize-icons/golden-chips.png",
            "Loyalty Points": "/assets/prize-icons/loyalty-points.png",
            "Shop Points": "/assets/prize-icons/shop-points.png",
            FunCoins: "/assets/prize-icons/win-coins.png",
          };

          const cardDetailsElement = document.querySelector(".cardDetails");
          cardDetailsElement.textContent = detailsMap[prize] || "Prize Details";
          if (prize === "Multiplier") {
            console.log("Multiplier");
            cardDetailsElement.classList.add("Multiplier");
          }

          cardDetailsElement.classList.remove("slide-in-right");
          void cardDetailsElement.offsetWidth; // Force reflow
          cardDetailsElement.classList.add("slide-in-right");
          cardDetailsElement.classList.add("slide-in-right");
          const cardWinsImgElement = document.querySelector(".cardWinsImg");
          if (cardImageMap[prize]) {
            cardWinsImgElement.src = cardImageMap[prize];
            cardWinsImgElement.style.display = ""; // Ensure the image is visible
          } else {
            cardWinsImgElement.style.display = "none"; // Hide the image if not applicable
          }

          // Special handling for Multiplier and Wheel Spins to hide the cardwins
          const cardWinsElement = document.querySelector(".cardwins");
          if (prize === "Multiplier" || prize === "Wheel Spins") {
            cardWinsElement.style.display = "none"; // Hide card wins
          } else {
            cardWinsElement.style.display = ""; // Default display
            cardWinsElement.classList.remove("slide-in-right");
            void cardWinsElement.offsetWidth; // Force reflow
            cardWinsElement.classList.add("slide-in-right");

            setTimeout(() => {
              this.sound.play("cash", { volume: 0.3 });

              // cardWinsElement.classList.add("animate__headShake");
            }, 500);
          }
          // if (cardWinsElement.style.display !== 'none') {
          //     cardWinsElement.classList.remove('slide-in-right');
          //     void cardWinsElement.offsetWidth; // Force reflow
          //     cardWinsElement.classList.add('slide-in-right');
          //   }

          let imagePath = "/assets/noi/default.png";
          let backimagePath = "/assets/noi/default.png";
          const iconImagePath =
            prizeToImage[prize] || "/assets/prizes/default-icon.png";

          switch (card_type) {
            case "Common":
              imagePath = "/assets/cards-bg/card_common.png";
              backimagePath = "/assets/card-back/cardback_common.png";
              break;
            case "Uncommon":
              imagePath = "/assets/cards-bg/card_uncommon.png";
              backimagePath = "/assets/card-back/cardback_uncommon.png";
              break;
            case "Rare":
              imagePath = "/assets/cards-bg/card_rare.png";
              backimagePath = "/assets/card-back/cardback_rare.png";
              break;
            case "Epic":
              imagePath = "/assets/cards-bg/card_epic.png";
              backimagePath = "/assets/card-back/cardback_epic.png";
              break;
            case "Legendary":
              imagePath = "/assets/cards-bg/card_legendary.png";
              backimagePath = "/assets/card-back/cardback_legendary.png";
              break;
          }

          const cardFrontImage = document.querySelector(".card .front img");
          cardFrontImage.src = imagePath;
          const cardFrontIcon = document.querySelector(
            ".card .front .front-icon"
          );
          if (cardFrontIcon) {
            cardFrontIcon.src = iconImagePath;
          }
          const cardBackImage = document.querySelector(".card .back img");
          cardBackImage.src = backimagePath;
          cardBackImage.style.filter = "brightness(0.5) invert(1)"; // Poți ajusta acest stil după preferințe

          // Aplică filtrul none după 250 ms pentru fiecare clic
          setTimeout(() => {
            cardBackImage.style.filter = "none";
          }, 250);

          const prizeTextElement = document.querySelector(".textPrize");
          prizeTextElement.textContent = `${prize}`;

          const amountTextElement = document.querySelector(".card .card-txt");
          amountTextElement.textContent = `${won_amount} `;
          //   if (amountTextElement) {
          //     // Call animateValue to count from 0 to the won_amount
          //     animateValue(amountTextElement, 0, won_amount, 1000); // 2000 milliseconds equals 2 seconds
          //   }

          this.openedCards.push({
            imagePath,
            iconImagePath,
            prize,
            won_amount,
            currencySymbol,
          });

          this.sound.play("open2Sound", { volume: 0.5 });

          this.cardIndex = (this.cardIndex + 1) % cardImages.length;
          self.playAnimation(lootbox, "open2", false).then(() => {
            self.isAnimationPlaying = false;

            self.playAnimation(lootbox, "idle_open", true);
          });
          const cardContainer = document.querySelector(".container");
          const lootContainer = document.querySelector(".loot");
          const card = document.querySelector(".card");
          const staticCards = document.querySelector(".chest-cards");
          const textCongrats = document.querySelector(".textPrize");
          const roundsElement = document.querySelector(".rounds");
          const chestCards = document.querySelector(".chest-cards");
          let currentValue = parseInt(roundsElement.textContent, 10);
          //   staticCards.classList.add("heartbeat");

          if (!isNaN(currentValue) && currentValue > 0) {
            currentValue -= 1;
            roundsElement.textContent = currentValue;
          }
          if (currentValue === 0) {
            this.gameOver = true; // Marchează sfârșitul jocului
            const tapText = document.querySelector(".tapText");
            const claimButton = document.querySelector(".claimButton");
            tapText.style.display = "none";
            setTimeout(() => {
              cardContainer.classList.add("hide");
              claimButton.classList.remove("hide");
              const canvasElement = document.querySelector(
                "#anime-container canvas"
              );
              if (canvasElement) {
                canvasElement.classList.add("anime-slide-up");
              }
              this.displayAllCards();
            }, 1500);
          }

          card.style.animation = "none";
          card.offsetHeight;
          card.style.animation = "";
          card.style.opacity = "1";
          card.style.visibility = "visible";
          staticCards.style.opacity = "1";
          card.classList.add("object");
          staticCards.classList.add("scale-in-center");

          chestCards.classList.add("heartbeat");
          textCongrats.style.animation = "none";
          textCongrats.offsetHeight;
          textCongrats.style.animation = "";
          textCongrats.classList.remove("slide-in-right");
          void textCongrats.offsetWidth;
          textCongrats.classList.add("slide-in-right");

          card.addEventListener("animationend", function handler2(e) {
            if (e.animationName === "new-animation") {
              card.removeEventListener("animationend", handler2);
              //   card.classList.remove("new");
              //   card.classList.add("slide-left");
              textCongrats.classList.add("slide-in-right");
            }
          });
        })
        .catch((error) => {
          console.error("Error fetching card data:", error);
          this.isAnimationPlaying = false;
        });
    });

    this.startAnimationSequence(lootbox);
  }
  //   displayAllCards() {
  //     const cardsDisplay = document.querySelector(".all-cards-display");

  //     cardsDisplay.innerHTML = ""; // Curăță containerul dacă este deja populat
  //     this.openedCards.forEach((card) => {
  //       const cardElement = document.createElement("div");
  //       cardElement.classList.add("card-down");
  //       cardElement.innerHTML = `
  //         <img src="${card.imagePath}" class="card-image">
  //         <img src="${card.iconImagePath}" class="card-icon">
  //         <div class="card-details">
  //           ${card.won_amount}
  //         </div>
  //       `;
  //       cardsDisplay.appendChild(cardElement);
  //     });
  //     adjustGridColumns();
  //   }
  displayAllCards() {
    const lootDiv = document.querySelector(".loot");
    lootDiv.innerHTML = ""; // Curăță tot conținutul div-ului loot

    // Crează și adaugă header-ul
    const lootHeader = document.createElement("div");
    lootHeader.className = "lootheader";
    lootHeader.innerHTML = `
      <div class="titleLoot">Your Loot</div>
      <div class="help"><span class="question">?</span></div>
    `;
    lootDiv.appendChild(lootHeader);

    // Crează containerul pentru carduri
    const cardsDisplay = document.createElement("div");
    cardsDisplay.className = "all-cards-display card-container";
    lootDiv.appendChild(cardsDisplay);

    // Populează containerul de carduri cu carduri din `openedCards`
    this.openedCards.forEach((card) => {
      const cardElement = document.createElement("div");
      cardElement.className = "card-down";
      cardElement.innerHTML = `
        <img src="${card.imagePath}" class="card-image">
        <img src="${card.iconImagePath}" class="card-icon">
        <div class="card-details">
          ${card.won_amount} 
        </div>
      `;
      cardsDisplay.appendChild(cardElement);
    });

    // Ajustează coloanele gridului dacă este necesar
    this.adjustGridColumns();
  }

  //   adjustGridColumns() {
  //     const cardsDisplay = document.querySelector('.all-cards-display');
  //     const numCards = cardsDisplay.children.length;
  //     if (numCards === 3) {
  //       cardsDisplay.classList.add("three-cards");
  //       cardsDisplay.classList.remove("four-cards");
  //     } else if (numCards === 8) {
  //       cardsDisplay.classList.add("four-cards");
  //       cardsDisplay.classList.remove("three-cards");
  //     } else {
  //       cardsDisplay.classList.remove("three-cards", "four-cards");
  //       cardsDisplay.style.gridTemplateColumns = `repeat(auto-fill, minmax(90px, 1fr))`;
  //     }
  //   }
  adjustGridColumns() {
    const cardsDisplay = document.querySelector(".all-cards-display");
    const numCards = cardsDisplay.children.length;
    if (numCards === 3) {
      cardsDisplay.classList.add("three-cards");
      cardsDisplay.classList.remove("four-cards");
    } else if (numCards === 8) {
      cardsDisplay.classList.add("four-cards");
      cardsDisplay.classList.remove("three-cards");
    } else {
      cardsDisplay.classList.remove("three-cards", "four-cards");
      cardsDisplay.style.gridTemplateColumns = `repeat(auto-fill, minmax(90px, 1fr))`;
    }
  }
  startAnimationSequence(lootbox) {
    this.playAnimation(lootbox, "in", false).then(() =>
      this.playAnimation(lootbox, "idle", true)
    );
    // .then(() => this.playAnimation(lootbox, 'idle', false)) // idle a second time
    // .then(() => this.playAnimation(lootbox, 'open', false))
    // .then(() => this.playAnimationForDuration(lootbox, 'idle_open', 3000)) // idle_open for 3 seconds
    // .then(() => this.playAnimationMultipleTimes(lootbox, 'open2', 5)) // open2 five times
    // .then(() => this.playAnimation(lootbox, 'close', false));
  }

  playAnimation(lootbox, animation, loop) {
    return new Promise((resolve) => {
      let trackEntry = lootbox.animationState.setAnimation(0, animation, loop);
      if (!loop) {
        trackEntry.listener = {
          complete: () => {
            resolve();
          },
        };
      } else {
        resolve();
      }
    });
  }

  playAnimationForDuration(lootbox, animation, duration) {
    return new Promise((resolve) => {
      lootbox.animationState.setAnimation(0, animation, true);
      setTimeout(() => {
        resolve();
      }, duration);
    });
  }

  playAnimationMultipleTimes(lootbox, animation, times) {
    return new Promise((resolve) => {
      const playNext = (count) => {
        if (count >= times) {
          resolve();
          return;
        }
        this.playAnimation(lootbox, animation, false).then(() =>
          playNext(count + 1)
        );
      };
      playNext(0);
    });
  }
}
document.addEventListener("DOMContentLoaded", function () {
  /**
   * Checks if the user is using an iPad and adds a corresponding class to the body element.
   */
  if (navigator.userAgent.match(/iPad/i)) {
    document.body.classList.add("is-ipad");
  } else {
    document.body.classList.add("not-ipad");
  }
  /**
   * Generates a random number of rounds (7 or 14) and displays them in the element with the 'rounds' class.
   */
  const roundsNumber = Math.random() < 0.5 ? 8 : 8;
  const roundsElement = document.querySelector(".rounds");
  if (roundsElement) {
    roundsElement.textContent = roundsNumber;
  }
  /**
   * Checks if the device is mobile and adjusts the game's width and height accordingly.
   */
  const isMobile = window.innerWidth < 800; // O simplă verificare; ajustează conform necesităților
  let gameWidth = window.innerWidth * window.devicePixelRatio;

  let gameHeight = window.innerHeight * window.devicePixelRatio;
  console.log("height", gameHeight);

  if (isMobile) {
    // Ajustează înălțimea pentru dispozitive mobile
    gameHeight = window.innerHeight * 1.4 * window.devicePixelRatio; // Sau orice altă logică specifică
    gameWidth = window.innerWidth * 1.2 * window.devicePixelRatio;
  }
  /**
   * Configuration for Phaser.
   */
  const config = {
    // width: window.innerHeight * window.devicePixelRatio,
    // height: window.innerHeight * window.devicePixelRatio,
    width: gameWidth,
    height: gameHeight,
    type: Phaser.WEBGL,
    // x: 200,
    // y: 520,
    parent: "anime-container",
    scene: [SpineDemo],
    transparent: true,
    fps: {
      target: 60,
      forceSetTimeOut: false,
    },
    //
    plugins: {
      scene: [
        { key: "SpinePlugin", plugin: spine.SpinePlugin, mapping: "spine" },
      ],
    },

    scale: {
      mode: Phaser.Scale.FIT,
      autoCenter: Phaser.Scale.CENTER_BOTH,

      // width: 1920,
      // height: 1080,
    },
    render: {
      pixelArt: false,
      antialias: true,
    },
  };

  const game = new Phaser.Game(config);
  console.log(game.config);
});
function adjustGridColumns() {
  const cardsDisplay = document.querySelector(".all-cards-display");
  const numCards = cardsDisplay.children.length;
  if (numCards === 3) {
    cardsDisplay.classList.add("three-cards");
    cardsDisplay.classList.remove("four-cards");
  } else if (numCards === 8) {
    cardsDisplay.classList.add("four-cards");
    cardsDisplay.classList.remove("three-cards");
  } else {
    cardsDisplay.classList.remove("three-cards", "four-cards");
    cardsDisplay.style.gridTemplateColumns = `repeat(auto-fill, minmax(90px, 1fr))`;
  }
}
